:root {
  --primary-color: #344e41;
  --secondary-color: #3a5a40;
  --tertiary-color: #588157;
  --highlight: #a3b18a;
  --bright: #dad7cd;
  --white: #ffffff;
  --primary-rgb: rgb(52 78 65);
}

body {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--bright);
  background: linear-gradient(305deg, var(--bright) 0 50%, var(--highlight) 50% 100%);
  background-repeat: no-repeat;
}

hr {
  border: 1px solid var(--primary-color);
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

/* Generic Heading Styles */
h1, h2, h3 {
  font-family: "Josefin Sans", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

h1 {
  text-transform: uppercase;
  font-kerning: auto;
}

h2 {
  font-size: 48px;
  color: var(--primary-color)
}

h3 {
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.headline {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.emph {
  color: var(--tertiary-color);
}

/* Header Styles */
header {
  color: var(--primary-color);
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* padding: 15px 0; */
  margin: 20px;
}

.mobile-heading {
  display: none;
}


/* Navigation Styles */
nav {
  display: flex;
  justify-content: flex-start;
  color: var(--bright);
  padding-top: 15px;
  flex-wrap: wrap;
}

nav ul {
  margin: 10px 20px 10px 20px;
  display: flex;
  flex-wrap: wrap;
}

nav ul li {
  display: inline;
  list-style-type: none;
  /* flex-basis: 200px; */
  padding: 15px 10px;
  opacity: 100%;
}

ul li a {
  text-decoration: none;
  opacity: 100%;
  padding: 10px;
  display: block;
}

nav ul li a {
  color: var(--primary-color);
  padding: 10px;
  transition: all 750ms ease-out 10ms;
  border-radius: 8px;
}


ul li a:hover, .project-links:hover {
  color: var(--bright); 
  /* animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: fadein; */
}

nav ul li a:hover {
  background-color: var(--primary-color);
  color: var(--bright);
}

.active {
  background-color: var(--secondary-color);
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  color: var(--bright)
}

/* main content section styles */
.main-content-wrapper {
  width: 95%;
}

.main-content-wrapper {
  padding-top: 15px;
  padding-bottom: 25px;
  margin: 0 auto;
}

/* ABOUT styles */
.about-content-wrapper {
  background-attachment: fixed;
  background-position: 80%;
  /* min-height: calc(100vh - 304px);
  overflow: hidden; */

}

.about {
  color: var(--primary-color);
  padding: 25px 50px;
  min-height: 100%;
  height: 100%;
}

.about-pic {
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
}

.about-pic img {
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
}

.about-pic div {
  text-align: center;
}

.proj-container {
  position: relative;
  width: 100%;
}

/* Portfolio Component Styles */
.portfolio-img {
  width: 100%;
  filter: drop-shadow(var(--primary-color) 2px 2px 2px);
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

/* .portfolio-img:hover {
  transform: scale(1.1);
} */

/* Project Component Styles */
.project-wrapper {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  color: var(--primary-color);
  text-align: center;
}

.proj-container:hover .portfolio-img {
  opacity: 0.2;
  transform: scale(1.03);
}

.proj-container:hover .project-wrapper {
  opacity: 1;
}

.tech-ul, .skills-ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  justify-content: center;
  padding-inline-start: 0;
}

.tech-li, .skills-li {
  display: inline;
  list-style-type: none;
  /* flex-basis: 100px; */
  padding: 5px 8px;
  font-weight: 600;
  
}

.project-links, .skills-li {
  padding: 5px 8px;
  margin: 3px;
  background-color: var(--secondary-color);
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  color: var(--bright);
}

.project-links a {
  color: var(--bright)
}

/* adding .project-links a:hover up to nav ul li a:hover */

.project-link-wrapper {
  display: flex;
  justify-content: center;
}

.project-links a {
  color: var(--bright)
}

/* Resume Section */

.resume h2, .resume h3 {
  color: var(--primary-color)
}


.skills-ul {
  justify-content: left;
}

/* Contact Form */
button {
  font-family: 'Inter', sans-serif;
}

.contact {
  padding: 25px;
  border-radius: 8px;
}

.contact h2 {
  color: var(--primary-color);
  margin-top: 15px;
}

/* Styles for the footer */

footer {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: space-around;
  height: 50px;
}

.icons a {
  transition: color 750ms ease-in 30ms;
}

.icons a:hover {
  color: var(--secondary-color);
}

/* This generates the height of the remainder of the content (minus the footer's
set height to ensure the footer is pushed to the bottom of the screen */
.body-wrapper, .about-content-wrapper {
  min-height: calc(100vh - 128px);
}


@media screen and (min-width: 700px) {
  .about-pic {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .about-pic div {
    text-align: left;
  }

  .about-pic img {
    margin-right: 100px;
    margin-bottom: 20px;
  }

  header {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .mobile-heading {
    display: inline;
  }
}